import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useTourStore = create(
  persist(
    (set) => ({
      seenTours: [],
      addSeenTour: (tourId) =>
        set((state) => ({
          seenTours: [...state.seenTours, tourId]
        })),
      hasSeen: (tourId) =>
        useTourStore.getState().seenTours.includes(tourId),
      resetTours: () =>
        set({ seenTours: [] }),
    }),
    {
      name: 'tour-storage',
    }
  )
)

export default useTourStore 